import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_A297C4CfS8 from "/vercel/path0/node_modules/@ambitiondev/nuxt-cookiebot/dist/runtime/plugin.js";
import plugin_client_LcKgStRyi6 from "/vercel/path0/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_tbFNToZNim from "/vercel/path0/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import ____vueObsevrve_ypcdJFLuWP from "/vercel/path0/plugins/____vueObsevrve.ts";
import http_U6xsJGI3U5 from "/vercel/path0/plugins/http.ts";
import i18n_VfGcjrvSkj from "/vercel/path0/plugins/i18n.ts";
import pinia_48xmdi2HHl from "/vercel/path0/plugins/pinia.ts";
import urlImage_aGKt6WCkko from "/vercel/path0/plugins/urlImage.ts";
import vue3_google_maps_dtbaznPoH4 from "/vercel/path0/plugins/vue3-google-maps.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_A297C4CfS8,
  plugin_client_LcKgStRyi6,
  plugin_tbFNToZNim,
  ____vueObsevrve_ypcdJFLuWP,
  http_U6xsJGI3U5,
  i18n_VfGcjrvSkj,
  pinia_48xmdi2HHl,
  urlImage_aGKt6WCkko,
  vue3_google_maps_dtbaznPoH4
]