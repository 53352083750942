export const language = [
    {
        "key": "updates",
        "en": "Updates",
        "la": "ອັບເດດ"
    },
    {
        "key": "laoLang",
        "en": "LA",
        "la": "ລາວ"
    },
    {
        "key": "enLang",
        "en": "EN",
        "la": "ອັງກິດ"
    },
    {
        "key": "home",
        "en": "Home",
        "la": "ໜ້າຫຼັກ"
    },
    {
        "key": "about 108",
        "en": "About 108.JOBS",
        "la": "ກ່ຽວກັບ 108.JOBS"
    },
    {
        "key": "about",
        "en": "About",
        "la": "ກ່ຽວກັບ"
    },
    {
        "key": "job search",
        "en": "Job search",
        "la": "ຄົ້ນຫາວຽກ"
    },
    {
        "key": "company search",
        "en": "Company search",
        "la": "ຄົ້ນຫາບໍລິສັດ"
    },
    {
        "key": "article",
        "en": "Articles",
        "la": "ບົດຄວາມ"
    },
    {
        "key": "contact us",
        "en": "Contact us",
        "la": "ຕິດຕໍ່ພວກເຮົາ"
    },
    {
        "key": "package",
        "en": "Price and Package",
        "la": "ລາຄາ ແລະ ແພັກເກັດ"
    },
    {
        "key": "post job",
        "en": "Post Job",
        "la": "ປະກາດວຽກ"
    },
    {
        "key": "cv search",
        "en": "Resume search",
        "la": "ຄົ້ນຫາ ຊີວະປະຫວັດ"
    },
    {
        "key": "login",
        "en": "Log in",
        "la": "ເຂົ້າສູ່ລະບົບ"
    },
    {
        "key": "logout",
        "en": "Log out",
        "la": "ອອກຈາກລະບົບ"
    },
    {
        "key": "register",
        "en": "Register",
        "la": "ລົງທະບຽນ"
    },
    {
        "key": "my profile",
        "en": "My profile",
        "la": "ໂປຣໄຟລ໌ຂອງຂ້ອຍ"
    },
    {
        "key": "my job",
        "en": "My jobs",
        "la": "ວຽກຂອງຂ້ອຍ"
    },
    {
        "key": "my job alert",
        "en": "My job alert",
        "la": "ແຈ້ງເຕືອນວຽກ"
    },
    {
        "key": "employerLink",
        "en": "For Employer",
        "la": "ສຳລັບບໍລິສັດ"
    },
    {
        "key": "company hiring now",
        "en": "Companies actively hiring",
        "la": "ບໍລິສັດທີ່ປະກາດວຽກ"
    },
    {
        "key": "jobseeker",
        "en": "JobSeeker",
        "la": "ຜູ້ຫາງານ"
    },
    {
        "key": "job by industry",
        "en": "Job by industry",
        "la": "ແບ່ງວຽກຕາມອຸດສາຫະກຳ"
    },
    {
        "key": "job by province",
        "en": "Job by province",
        "la": "ແບ່ງວຽກຕາມແຂວງ"
    },
    {
        "key": "recommend job",
        "en": "Recommended job",
        "la": "ວຽກທີ່ແນະນຳ"
    },
    {
        "key": "find dream job",
        "en": "Find your dream job now",
        "la": "ວຽກທີ່ປະກາດຮັບສະໝັກຢູ່"
    },
    {
        "key": "explore more",
        "en": "Explore more",
        "la": "ເບີ່ງວຽກທັງໝົດ"
    },
    {
        "key": "jobs",
        "en": "Jobs",
        "la": "ຕຳແໜ່ງ"
    },
    {
        "key": "employer",
        "en": "Employer",
        "la": "ບໍລິສັດ"
    },
    {
        "key": "update your profile",
        "en": "! Update your profile to Express interest",
        "la": "! ກະລຸນາອັບເດດຂໍ້ມູນໂປຣໄຟລ໌ຂອງທ່ານ"
    },
    {
        "key": "download app",
        "en": "Download the 108job app.",
        "la": "ດາວໂຫຼດແອັບ 108job"
    },
    {
        "key": "download description",
        "en": "Effortless applications! Create a profile, get instant job alerts, and apply with one click on the 108Jobs app.",
        "la": "ໃຊ້ງານງ່າຍ ພຽງແຕ່ສ້າງໂປຣໄຟລ, ຮັບການແຈ້ງເຕືອນ ແລະ ສະໝັກວຽກພຽງແຕ່ຄຣິກດຽວເທິງແອັບ 108Jobs"
    },
    {
        "key": "follow",
        "en": "Follow",
        "la": "ຕິດຕາມ"
    },
    {
        "key": "following",
        "en": "Following",
        "la": "ກຳລັງຕິດຕາມ"
    },
    {
        "key": "follower",
        "en": "Followers",
        "la": "ຄົນຕິດຕາມ"
    },
    {
        "key": "save job",
        "en": "Save Job",
        "la": "ບັນທຶກວຽກ"
    },
    {
        "key": "saved",
        "en": "Saved",
        "la": "ບັນທຶກແລ້ວ"
    },
    {
        "key": "hide job",
        "en": "Hide Job",
        "la": "ເຊື່ອງວຽກ"
    },
    {
        "key": "select by",
        "en": "Select by ",
        "la": "ເລືອກຕາມ"
    },
    {
        "key": "location",
        "en": "Location",
        "la": "ສະຖານທີ"
    },
    {
        "key": "education",
        "en": "Education",
        "la": "ການສຶກສາ"
    },
    {
        "key": "industry",
        "en": "Industry",
        "la": "ອຸດສາຫະກຳ"
    },
    {
        "key": "company",
        "en": "Company",
        "la": "ບໍລິສັດ"
    },
    {
        "key": "company size",
        "en": "Company Size",
        "la": "ຂະໜາດບໍລິສັດ"
    },
    {
        "key": "company name",
        "en": "Company name",
        "la": "ຊື່ບໍລິສັດ"
    },
    {
        "key": "all company",
        "en": "All Companies",
        "la": "ບໍລິສັດທັງໝົດ"
    },
    {
        "key": "clear all filter",
        "en": "Clear all filters",
        "la": "ລ້າງຕົວເລືອກທັງໝົດ"
    },
    {
        "key": "ok",
        "en": "OK",
        "la": "ຕົກລົງ"
    },
    {
        "key": "close",
        "en": "Close",
        "la": "ປິດ"
    },
    {
        "key": "search",
        "en": "Search ",
        "la": "ຄົ້ນຫາ"
    },
    {
        "key": "job title",
        "en": "Job title",
        "la": "ຊື່ຕຳແໜ່ງ"
    },
    {
        "key": "all article",
        "en": "All article",
        "la": "ບົດຄວາມທັງໝົດ"
    },
    {
        "key": "all categories",
        "en": "All categories",
        "la": "ໝວດໝູ່ທັງໝົດ"
    },
    {
        "key": "job available",
        "en": "Job Available",
        "la": "ວຽກທີ່ເປີດຮັບສະໝັກ"
    },
    {
        "key": "all job available",
        "en": "Job Available Total",
        "la": "ວຽກທີ່ເປີດຮັບສະໝັກທັງໝົດ"
    },
    {
        "key": "job function",
        "en": "Job function",
        "la": "ປະເພດວຽກ"
    },
    {
        "key": "filter",
        "en": "Filters",
        "la": "ຕົວກອງ"
    },
    {
        "key": "all locaotion",
        "en": "All location",
        "la": "ສະຖານທີທັງໝົດ"
    },
    {
        "key": "all company size",
        "en": "All company size",
        "la": "ຂະໜາດບໍລິສັດທັງໝົດ"
    },
    {
        "key": "company type",
        "en": "Company type",
        "la": "ປະເພດບໍລິສັດ"
    },
    {
        "key": "hiring now",
        "en": "Hiring now",
        "la": "ປະກາດວຽກຕອນນີ້"
    },
    {
        "key": "clear all",
        "en": "Clear all",
        "la": "ລ້າງທັງໝົດ"
    },
    {
        "key": "view more",
        "en": "View more",
        "la": "ເບິ່ງເພີ່ມ"
    },
    {
        "key": "photo",
        "en": "Photos",
        "la": "ຮູບພາບ"
    },
    {
        "key": "photo gallery",
        "en": "Photo gallery",
        "la": "ຄັງຮູບພາບ"
    },
    {
        "key": "people",
        "en": "People",
        "la": "ບຸກຄະລາກອນ"
    },
    {
        "key": "address",
        "en": "Address",
        "la": "ທີ່ຢູ່"
    },
    {
        "key": "about company",
        "en": "About Company",
        "la": "ກ່ຽວກັບບໍລິສັດ"
    },
    {
        "key": "benefit",
        "en": "Beneftis",
        "la": "ສະຫວັດດີການ"
    },
    {
        "key": "video",
        "en": "Video",
        "la": "ວິດີໂອ"
    },
    {
        "key": "job open",
        "en": "Job opening",
        "la": "ວຽກທີ່ເປີດຮັບສະໝັກ"
    },
    {
        "key": "contact",
        "en": "Contact",
        "la": "ຕິດຕໍ່"
    },
    {
        "key": "submit cv",
        "en": "Express interest",
        "la": "ສົນໃຈສະໝັກວຽກ"
    },
    {
        "key": "already submit",
        "en": "Already express interested",
        "la": "ທ່ານໄດ້ກົດສົ່ງສົນໃຈສະໝັກວຽກແລ້ວ"
    },
    {
        "key": "cancel",
        "en": "Cancel",
        "la": "ຍົກເລີກ"
    },
    {
        "key": "submitted cv",
        "en": "Express interested",
        "la": "ກົດສົນໃຈແລ້ວ"
    },
    {
        "key": "you done submit cv",
        "en": "You are finished Express interested",
        "la": "ທ່ານໄດ້ສົ່ງສະແດງຄວາມສົນໃຈສະໝັກວຽກແລ້ວ"
    },
    {
        "key": "product and service",
        "en": "Product and Service",
        "la": "ຜະລິດຕະພັນ ແລະ ການບໍລິການ"
    },
    {
        "key": "submit cv meaning",
        "en": "Clicking the \"Express Interest\" button will make your profile visible to hiring managers' emails. This increases your chances of being contacted directly by a potential employer",
        "la": "ການກົດປຸ່ມ ສະແດງຄວາມສົນໃຈສະໝັກວຽກກັບບໍລິສັດ ປະຫວັດໂດຍຫຍໍ້ຂອງທ່ານຈະຖືກສົ່ງຫາຝ່າຍບຸກຄະລາກອນຂອງບໍລິສັດດັງກ່າວແລະ ທ່ານອາດຈະໄດ້ຮັບການຕິດຕໍ່ກັບຈາກບໍລິສັດ."
    },
    {
        "key": "tips&tutorial",
        "en": "Tips & Tutorial",
        "la": "ຄຳແນະນຳ ແລະ ວິທີການ"
    },
    {
        "key": "have not login",
        "en": "You haven't login yet",
        "la": "ທ່ານຍັງບໍ່ໄດ້ເຂົ້າສູ່ລະບົບ"
    },
    {
        "key": "phone",
        "en": "Phone",
        "la": "ເບີໂທລະສັບ"
    },
    {
        "key": "email",
        "en": "Email",
        "la": "ອີເມວ"
    },
    {
        "key": "password",
        "en": "Password",
        "la": "ລະຫັດຜ່ານ"
    },
    {
        "key": "forgot pass",
        "en": "Forgot password",
        "la": "ລືມລະຫັດຜ່ານ"
    },
    {
        "key": "login with",
        "en": "Login with ",
        "la": "ເຂົ້າສູ່ລະບົບດ້ວຍ"
    },
    {
        "key": "register with",
        "en": "Register ",
        "la": "ລົງທະບຽນດ້ວຍ"
    },
    {
        "key": "don't have ac",
        "en": "Don't have an account",
        "la": "ບໍ່ມີບັນຊີ"
    },
    {
        "key": "fullname",
        "en": "Fulll Name",
        "la": "ຊື່ເຕັມ"
    },
    {
        "key": "enter your",
        "en": "Enter your ",
        "la": "ປ້ອນຂໍ້ມູນ"
    },
    {
        "key": "already have ac",
        "en": "Already have an account",
        "la": "ມີບັນຊີແລ້ວ"
    },
    {
        "key": "enter",
        "en": "Enter ",
        "la": "ປ້ອນ"
    },
    {
        "key": "or",
        "en": "or ",
        "la": "ຫຼື "
    },
    {
        "key": "create",
        "en": "Create",
        "la": "ສ້າງ"
    },
    {
        "key": "related job",
        "en": "Related Jobs",
        "la": "ວຽກທີ່ກ່ຽວຂ້ອງ"
    },
    {
        "key": "apply",
        "en": "Apply this job",
        "la": "ສະໝັກຕຳແໜ່ງນີ້"
    },
    {
        "key": "applied",
        "en": "aleadly apply ",
        "la": "ສະໝັັກແລ້ວ"
    },
    {
        "key": "preferred language",
        "en": "Preferred Language",
        "la": "ພາສາທີ່ຕ້ອງການ"
    },
    {
        "key": "salary",
        "en": "Salary",
        "la": "ເງີນເດືອນ"
    },
    {
        "key": "experience",
        "en": "Experience",
        "la": "ປະສົບການ"
    },
    {
        "key": "reset pass",
        "en": "Reset Password",
        "la": "ກູ້ຄືນລະຫັດຜ່ານ"
    },
    {
        "key": "new pass",
        "en": "New Password",
        "la": "ລະຫັດຜ່ານໃຫ່ມ"
    },
    {
        "key": "confirm",
        "en": "Confrm",
        "la": "ຍືນຍັນ"
    },
    {
        "key": "verify code",
        "en": "Verification Code",
        "la": "ລະຫັດຍືນຍັນ"
    },
    {
        "key": "send otp description",
        "en": "We sent a verification code to your registered address.",
        "la": "ພວກເຮົາໄດ້ສົ່ງລະຫັດຍືນຍັນໄປຫາບັນຊີທີ່ທ່ານລົງທະບຽນ"
    },
    {
        "key": "don't receive code",
        "en": "Didn't receive code?",
        "la": "ຍັງບໍ່ໄດ້ຮັບລະຫັດຍືນຍັນ?"
    },
    {
        "key": "resend",
        "en": "Resend New",
        "la": "ສົ່ງອີກຄັ້ງ"
    },
    {
        "key": "verify",
        "en": "Verify",
        "la": "ຍືນຍັນ"
    },
    {
        "key": "insert info",
        "en": "Please insert your information",
        "la": "ກະລຸນາປ້ອນຂໍ້ມູນ"
    },
    {
        "key": "reset via description",
        "en": "Select which is you need to reset via ?",
        "la": "ຕ້ອງການກູ້ຄືນລະຫັດຜ່ານຊ່ອງທາງໃດ"
    },
    {
        "key": "reset via",
        "en": "Reset via ",
        "la": "ກູ້ຄືນດ້ວຍ"
    },
    {
        "key": "next",
        "en": "Next",
        "la": "ຕໍ່ໄປ"
    },
    {
        "key": "link sent to mail",
        "en": "Link reset will be sent to your email address registered",
        "la": "ລະຫັດຍືນຍັນຈະສົ່ງໄປຫາອີເມວຂອງທ່ານ"
    },
    {
        "key": "link sent to phone",
        "en": "Link reset will be sent to your phone number registered",
        "la": "ລະຫັດຍືນຍັນຈະສົ່ງໄປຫາເບີໂທລະສັບຂອງທ່ານ"
    },
    {
        "key": "continue find job",
        "en": "Continue find job",
        "la": "ສືບຕໍ່ຄົ້ນຫາວຽກ"
    },
    {
        "key": "expired on",
        "en": "Expired on",
        "la": "ປິດຮັບສະໝັກ"
    },
    {
        "key": "share on",
        "en": "Share on",
        "la": "ແບ່ງປັນ"
    },
    {
        "key": "total view",
        "en": "Total views",
        "la": "ຍອດເຂົ້າຊົມ"
    },
    {
        "key": "job summary",
        "en": "Job summary",
        "la": "ລາຍລະອຽດວຽກໂດຍຫຍໍ້"
    },
    {
        "key": "minute",
        "en": "minute",
        "la": "ນາທີ"
    },
    {
        "key": "minute ago",
        "en": "minute ago",
        "la": "ນາທີຜ່ານມາ"
    },
    {
        "key": "minutes ago",
        "en": "minutes ago",
        "la": "ນາທີຜ່ານມາ"
    },
    {
        "key": "hour",
        "en": "hour(s)",
        "la": "ຊົ່ວໂມງ"
    },
    {
        "key": "hour ago",
        "en": "hour ago",
        "la": "ຊົ່ວໂມງຜ່ານມາ"
    },
    {
        "key": "hours ago",
        "en": "hours ago",
        "la": "ຊົ່ວໂມງຜ່ານມາ"
    },
    {
        "key": "day",
        "en": "day",
        "la": "ມີື້"
    },
    {
        "key": "days",
        "en": "day(s)",
        "la": "ມີື້"
    },
    {
        "key": "day ago",
        "en": "day ago",
        "la": "ມື້ຜ່ານມາ"
    },
    {
        "key": "days ago",
        "en": "days ago",
        "la": "ມື້ຜ່ານມາ"
    },
    {
        "key": "week",
        "en": "week",
        "la": "ອາທິດ"
    },
    {
        "key": "week ago",
        "en": "week ago",
        "la": "ອາທິດຜ່ານມາ"
    },
    {
        "key": "weeks ago",
        "en": "weeks ago",
        "la": "ອາທິດຜ່ານມາ"
    },
    {
        "key": "month",
        "en": "month",
        "la": "ເດືອນ"
    },
    {
        "key": "months",
        "en": "months",
        "la": "ເດືືອນຜ່ານມາ"
    },
    {
        "key": "ago",
        "en": "ago",
        "la": "ຜ່ານມາ"
    },
    {
        "key": "view",
        "en": "View",
        "la": "ເບີ່ງ"
    },
    {
        "key": "finish",
        "en": "Finished",
        "la": "ສຳເລັດແລ້ວ"
    },
    {
        "key": "you are finish reset",
        "en": "You are finished reset password",
        "la": "ທ່ານປ່ຽນລະຫັດຜ່ານສຳເລັດແລ້ວ"
    },
    {
        "key": "accessibility",
        "en": "Accessibility",
        "la": "ການເຂົ້າເຖິງ"
    },
    {
        "key": "for disabled people",
        "en": "this job is open for disabled people",
        "la": "ຕຳແໜ່ງນີ້ຄົນພິການສາມາດສະໝັກໄດ້"
    },
    {
        "key": "you are apply",
        "en": "You're going to apply for a job",
        "la": "ທ່ານກຳລັງສະໝັກວຽກ"
    },
    {
        "key": "preview cv",
        "en": "Preview CV",
        "la": "ເບີ່ງຕົວຢ່າງ CV"
    },
    {
        "key": "apply position",
        "en": "Apply Position",
        "la": "ຕຳແໜ່ງທີ່ສະໝັກ"
    },
    {
        "key": "apply company",
        "en": "Apply Company",
        "la": "ບໍລິສັດທີ່ສະໝັກ"
    },
    {
        "key": "cover letter is require",
        "en": "Cover letter is require",
        "la": "ຕ້ອງການ Cover Letter"
    },
    {
        "key": "plz upload cvL",
        "en": "Please upload Cover Letter",
        "la": "ກະລຸນາອັບໂຫລດ Cover Letter"
    },
    {
        "key": "yes apply",
        "en": "Yes, Apply",
        "la": "ຕົກລົງ, ສະໝັກເລີຍ"
    },
    {
        "key": "upload cvL",
        "en": "Upload Cover Letter",
        "la": "ອັບໂຫລດ Cover Letter"
    },
    {
        "key": "or copy link",
        "en": "Or copy link",
        "la": "ຫຼື ກ໋ອບປີ້ Link ໜ້ານີ້"
    },
    {
        "key": "share this page",
        "en": "Share this page",
        "la": "ແບ່ງປັນໜ້ານີ້"
    },
    {
        "key": "share via",
        "en": "Share via",
        "la": "ແບ່ງປັນດ້ວຍ"
    },
    {
        "key": "disable people",
        "en": "Disable people",
        "la": "ວຽກສຳລັບຄົນພິການ"
    },
    {
        "key": "cv not approved plz update",
        "en": "Your CV is not yet been approved, please update your profile",
        "la": "CV ຂອງທ່ານຍັງບໍ່ໄດ້ຮັບການອະນຸມັດ,​ ກະລຸນາອັບເດດຂໍ້ມູນໂປຣໄຟລ໌"
    },
    {
        "key": "cv not approved",
        "en": "CV is not approved",
        "la": "CV ຍັງບໍ່ໄດ້ຮັບການອະນຸມັດ"
    },
    {
        "key": "resend code",
        "en": "Resend code after ",
        "la": "ສາມາດສົ່ງລະຫັດອີກຄັ້ງໃນ"
    },
    {
        "key": "create pass",
        "en": "Create password",
        "la": "ຕັ້ງລະຫັດຜ່ານໃຫ່ມ"
    },
    {
        "key": "information not found",
        "en": "Information not found",
        "la": "ບໍ່ມີຂໍ້ມູນ"
    }
]
