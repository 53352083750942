import { defineStore } from 'pinia'
import useFetchCustom from '~/services/global-useFetch'
import { seekerUrl } from '~/services/global-variable'
import { useRouter, useCookie } from '#app'
import { ref, computed } from 'vue'

export const useAuthStore = defineStore('auth', () => {
  const seekerURL = seekerUrl
  const isRegister = ref(false)
  const token = ref<string | null>(null)
  const isAuth = computed(() => !!token.value)
  const isLoginModal = ref(false)

  // login modal
  const LoginModal = (data: any) => {
    isLoginModal.value = data
  }

  // login modal new tab to seeker profile and refresh page
  const LoginForm = async (form: any) => {
    let msg = ''
    try {
      const res: any = await useFetchCustom('/seeker-login-web')
        .post(form)
        .json()
      if (res.statusCode.value == 401) {
        msg = 'Incorrect username or password '
        return msg
      }
      if (res.statusCode.value === 200) {
        const data: any = res.data.value
        LoginModal(false)
        msg = 'Login finish'
        const tokenData = data.token
        token.value = tokenData
        const useCookies = useCookie('token', {
          secure: true, // Ensure HTTPS is enabled for secure cookies
          sameSite: 'none',
          expires: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
        })
        useCookies.value = tokenData
        if (process.client) {
          window.open(`${seekerURL}?token=${tokenData}`, '_blank')
        }
        // Refresh the page
        const router = useRouter()
        router.go(0)
      }
    } catch (e: any) {
      msg = e.message
      return msg
    }
    return msg
  }

  // Register
  const RegisterForm = async (form: any) => {
    let msg = ''
    console.log('check form', form)
    try {
      const res: any = await useFetchCustom('/register-seeker-web')
        .post(form)
        .json()
      if (res.statusCode.value == 409) {
        msg = 'This email has register already...!' || res.error
        return msg
      }
      if (res.statusCode.value == 200) {
        isRegister.value = false
      }
    } catch (e: any) {
      msg = e.message
      return msg
    }
    // return msg
  }

  // sign out
  const signOut = async () => {
    token.value = null
    if (!token.value) {
      const router = useRouter()
      router.replace('/')
      window.location.reload()
    }
  }

  return {
    token,
    isAuth,
    isLoginModal,
    isRegister,
    RegisterForm,
    signOut,
    LoginModal,
    LoginForm
  }
})
