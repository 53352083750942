export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, user-scalable=no, viewport-fit=cover"},{"name":"apple-mobile-web-app-capable","content":"yes"},{"name":"apple-mobile-web-app-status-bar-style","content":"black"},{"name":"mobile-web-app-capable","content":"yes"},{"hid":"description","name":"description","content":"108.jobs is No.1 job site in Laos. 108.jobs is part of the first and only one one-stop service multi-channel recruitment media in Laos 108JOB which is under the company called 108-1009 Group Co.,ltd.; the local company here in Laos."},{"name":"keywords","content":"108Job, 108Jobs, 108Job.la, 108, Job in Laos, Job in Lao, Laos Job, Job Laos, Recruit in Laosjobs, job, lao"},{"name":"robots","content":"index, follow, max-image-preview:large"},{"property":"og:type","content":"website"},{"property":"og:site_name","content":"108.jobs"},{"property":"og:title","content":"108.jobs"},{"property":"og:description","content":"108.jobs is No.1 job site in Laos. 108.jobs is part of the first and only one one-stop service multi-channel recruitment media in Laos 108JOB which is under the company called 108-1009 Group Co.,ltd.; the local company here in Laos."},{"property":"og:url","content":"https://108.jobs/"},{"property":"og:image","content":"https://lab-108-bucket.s3.ap-southeast-1.amazonaws.com/assistant-logo/108.Jobs-Logo-01.jpg"},{"property":"og:image:width","content":"740"},{"property":"og:image:height","content":"300"}],"link":[{"rel":"icon","type":"image/*","href":"/favicon.png"}],"style":[],"script":[],"noscript":[],"title":"108.Jobs"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000