<template>
  <footer>
    <div class="container">
      <div class="columns">
        <div class="column">
          <h3>{{ $t('about') }}</h3>
          <hr />
          <ul>
            <li>
              <nuxt-link to="/about-us">{{ $t('about 108') }}</nuxt-link>
            </li>
            <li>
              <nuxt-link to="/privacy-policy">Privacy Policy</nuxt-link>
            </li>
          </ul>
        </div>
        <div class="column">
          <h3>{{ $t('jobseeker') }}</h3>
          <hr />
          <ul>
            <li>
              <a @click="RegisterPage">{{ $t('register') }}</a>
            </li>
            <li>
              <nuxt-link to="/job-search">{{ $t('job search') }}</nuxt-link>
            </li>
            <li>
              <nuxt-link to="/article">{{ $t('article') }}</nuxt-link>
            </li>
          </ul>
        </div>
        <div class="column">
          <h3>{{ $t('employer') }}</h3>
          <hr />
          <ul>
            <li>
              <a @click="toEmployerPage">{{ $t('post job') }}</a>
            </li>
            <li>
              <a @click="toEmployerPage">{{ $t('cv search') }}</a>
            </li>
          </ul>
        </div>
        <div class="column">
          <h3>Job Posting Inquiry</h3>
          <hr />
          <ul>
            <li>(+856) 20 2221 7639</li>
            <li>(+856) 20 2803 4427</li>
            <li>Mon - Fri : 8:30am - 5:30pm</li>
            <li>Email: sales@108.jobs</li>
          </ul>
        </div>
        <div class="download-card">
          <div class="download-card-start">
            <h3>Apply on the go</h3>
            <p>Get real-time job updates on our App</p>
          </div>
          <div class="download-card-end">
            <a
              href="https://play.google.com/store/apps/details?id=com.onehundredeightjobs.app&pcampaignid=web_share"
              target="_blank"
            >
              <img
                src="../../assets/images/google-play-badge.png"
                alt="Google-play-store"
              />
            </a>
            <a
              href="https://apps.apple.com/th/app/108-jobs/id1169598302?"
              target="_blank"
              ><img
                src="../../assets/images/app-store-badge.png"
                alt="Apple-sotre"
            /></a>
          </div>
        </div>
      </div>

      <div class="copyright">
        <p>
          Copyright © 108-1009 Group Co., Ltd. | 1st Floor, 108Hill Building
          Dongpaina Road, Saphanthong Village, Sisattanak District, Vientiane
          Capital, Lao PDR.
        </p>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { useAuthStore } from '~/store/auth'
const router = useRouter()
const employerLink: any = useNuxtApp().$employer
const RegisterPage = () => {
  // Auth.isRegister = true
  router.push('/auth/register')
}

const toEmployerPage = () => {
  window.location.href = employerLink
}
</script>

<style scoped lang="scss">
footer {
  padding: 2rem 0 0;
  position: relative;
  color: #fff;
  background-color: #000;
  .columns {
    padding: 2rem 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    @include mobile {
      flex-direction: column;
    }
    .column {
      flex-shrink: 0;
      flex-grow: 1;
      h3 {
        font-weight: 700;
        font-size: var(--md-font);
      }
      hr {
        content: '';
        display: block;
        width: 30px;
        height: 3px;
        border-radius: 3px;
        margin: 0.75rem 0;
        background-color: var(--primary-color);
      }
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        li {
          line-height: 1.5;
        }
      }
    }
    .download-card {
      padding: 1.5rem;
      background-color: rgba(#fff, 0.15);
      border-radius: 6px;
      display: inline-block;
      .download-card-start {
        h3 {
          font-weight: 700;
          font-size: var(--md-font);
        }
        p {
          font-size: var(--sm-font);
        }
      }
      .download-card-end {
        margin-top: 1rem;
        display: flex;
        gap: 1rem;
        a {
          img {
            max-height: 40px;
            height: 100%;
          }
        }
      }
    }
  }
}

.copyright {
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-size: var(--xsm-font);
    text-align: center;
  }
}
</style>
